import {Page, NavController, NavParams} from 'ionic-angular';
import {HomePage} from '../home/home'
import {ProductsPage} from '../products/products'

@Page({
	templateUrl: 'build/pages/groups/groups.html'
})

export class GroupsPage {
	static get parameters() {
		return [[NavController], [NavParams]];
	}

	constructor(nav, navParams) {
		this.nav = nav;
	}

	navigate(gid) {
		this.nav.push(ProductsPage, {
			gid: gid
		});
	}
}
