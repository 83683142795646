import 'es6-shim';
import {enableProdMode} from 'angular2/core';
import {RouteConfig} from 'angular2/router';
import {App, IonicApp, Platform} from 'ionic-angular';

import {HomePage} from './pages/home/home';
import {KurumsalPage} from './pages/kurumsal/kurumsal';
import {ReferanslarPage} from './pages/referanslar/referanslar';
import {MultimediaPage} from './pages/multimedia/multimedia';
import {ContactPage} from './pages/contact/contact';
import {GroupsPage} from './pages/groups/groups';
import {ProductsPage} from './pages/products/products';
import {ProductPage} from './pages/product/product';

enableProdMode();

@RouteConfig([
	{ path: '/', component: HomePage, name: 'HPS Hydro' },
	{ path: '/kurumsal', component: KurumsalPage, name: 'HPS Hydro | Kurumsal' },
	{ path: '/urun-gruplari', component: GroupsPage, name: 'HPS Hydro | Ürün Grupları' },
	{ path: '/urun-gruplari/:gid', component: ProductsPage },
	{ path: '/urun-gruplari/urun-goster/:pid', component: ProductPage },
	{ path: '/referanslar', component: ReferanslarPage, name: 'HPS Hydro | Referanslar' },
	{ path: '/multimedya', component: MultimediaPage, name: 'HPS Hydro | Multimedya' },
	{ path: '/iletisim', component: ContactPage, name: 'HPS Hydro | İletişim' }
])

@App({
	templateUrl: 'build/app.html',
	config: {
		mode: 'md'
	}
})
class MyApp {
	static get parameters() {
		return [[IonicApp], [Platform]];
	}

	constructor(app, platform) {
		this.app = app;
		this.platform = platform;

		// this.rootPage = HomePage;
	}

	openPage(page) {
		let nav = this.app.getComponent('nav');
		if( page == 'home' ) {
			nav.setRoot(HomePage);
		}
		if( page == 'kurumsal' ) {
			nav.setRoot(KurumsalPage);
		}
		if( page == 'referanslar' ) {
			nav.setRoot(ReferanslarPage);
		}
		if( page == 'multimedia' ) {
			nav.setRoot(MultimediaPage);
		}
		if( page == 'contact' ) {
			nav.setRoot(ContactPage);
		}
		if( page == 'groups' ) {
			nav.setRoot(GroupsPage);
		}
	}
}
