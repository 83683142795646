import {Page, NavController, NavParams} from 'ionic-angular';
import {Http} from 'angular2/http';
import 'rxjs/add/operator/map';
import {HomePage} from '../home/home'
import {ProductPage} from '../product/product'

@Page({
	templateUrl: 'build/pages/products/products.html'
})

export class ProductsPage {
	static get parameters() {
		return [[NavController], [NavParams], [Http]];
	}

	constructor(nav, navParams, http) {
		this.nav = nav;
		this.http = http;

		this.gid = navParams.get('gid');

		this.group = null;
		this.products = null;

		this.http.get('http://hydrodua.com/api/products?gid='+this.gid).map(res => res.json()).subscribe(data => {
			this.group = data;
			this.products = data.products;
		});
	}

	navigate(pid) {
		this.nav.push(ProductPage, {
			pid: pid
		});
	}

}
