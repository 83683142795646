import {Page, NavController, NavParams} from 'ionic-angular';
import {HomePage} from '../home/home'

@Page({
	templateUrl: 'build/pages/referanslar/referanslar.html'
})

export class ReferanslarPage {
	static get parameters() {
		return [[NavController], [NavParams]];
	}

	constructor(nav, navParams) {
		this.nav = nav;
	}
}
