import {Page, NavController, NavParams} from 'ionic-angular';
import {Http} from 'angular2/http';
import 'rxjs/add/operator/map';
import {HomePage} from '../home/home'

@Page({
	templateUrl: 'build/pages/product/product.html'
})

export class ProductPage {
	static get parameters() {
		return [[NavController], [NavParams], [Http]];
	}

	constructor(nav, navParams, http) {
		this.nav = nav;
		this.http = http;

		this.pid = navParams.get('pid');

		console.log(this.pid);

		this.product = null;

		this.http.get('http://hydrodua.com/api/products?pid='+this.pid).map(res => res.json()).subscribe(data => {
			this.product = data;
		});
	}
}
