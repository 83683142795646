import {Page, NavController, NavParams} from 'ionic-angular';
import {HomePage} from '../home/home'

@Page({
	templateUrl: 'build/pages/kurumsal/kurumsal.html'
})

export class KurumsalPage {
	static get parameters() {
		return [[NavController], [NavParams]];
	}

	constructor(nav, navParams) {
		this.nav = nav;
	}
}
