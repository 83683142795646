import {Page, NavController, NavParams} from 'ionic-angular';
import {ProductsPage} from '../products/products'

@Page({
	templateUrl: 'build/pages/home/home.html'
})

export class HomePage {
	static get parameters() {
		return [[NavController], [NavParams]];
	}

	constructor(nav, navParams) {
		this.nav = nav;

		this.mySlideOptions = {
			initialSlide: 1,
			autoplay: 5000,
			loop: true
		};
	}

	navigate(gid) {
		this.nav.push(ProductsPage, {
			gid: gid
		});
	}

}
